const largeSize = ['space']
const middleSize = ['meta', 'capslock', 'shift', 'tab', 'control', 'alt', 'fn', 'enter', 'backspace']

export const getSize = (keycapText) => {
  if (largeSize.includes(keycapText)) return 'Large'
  if (middleSize.includes(keycapText)) return 'Middle'
  return 'Small'
}

// 50px + 14px
// 50px * 1.5 + 14px
// 50px * 2 + 14px
export const getSizeKeycap = (keycapText) => {
  if (largeSize.includes(keycapText)) return 114
  if (middleSize.includes(keycapText)) return 64
  return 89
}

export const getColor = (keycapText, { colors }) => {


  if (['meta', 'control'].includes(keycapText)) return colors.primary
  if (middleSize.includes(keycapText)) return colors.secondary
  return colors.default
}
