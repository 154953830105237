import style from './style.module.css'

export const LabelConfig = ({ children, label, exportStyle, ...rest }) => {
  return (
    <div {...rest}>
      <span className={`${style.label} ${exportStyle ? style.exportStyle : ''} `}>{label}</span>
      <div className={style.content}>
        {children}
      </div>
    </div>
  )
}
