import { forwardRef } from 'react'
import style from './style.module.css'
import { useRefDimensions } from '../../hooks/useRefDimensions'

export const CanvasWidth = forwardRef((_, ref) => {
  const dimensions = useRefDimensions(ref)
  if (!dimensions) return <div className={style.placeholder} />
  return (
    <div className={style.canvasWidth} style={{ width: dimensions?.width }}>
      <div className={style.left} />
      <span className={style.size}>{dimensions?.width} px</span>
      <div className={style.right} />
    </div>
  )
})
