export const themes = [
  {
    id: '1',
    label: 'MAGIC KYBD',
    key: 'magic-kybd',
    gradient: 'linear-gradient(303.69deg, #F3EC78 6.67%, #AF4261 50.41%, #2A2A72 93.33%)',
    colorScheme: [
      {
        id: '1',
        key: 'magic-kybd-dark',
        label: 'Magic dark keyboard',
        background: 'linear-gradient(303.69deg, #F3EC78 6.67%, #AF4261 50.41%, #2A2A72 93.33%)',
        schemeBarColors: ['black'],
        themeProps: {
          variant: 'dark',
        }
      },
      {
        id: '2',
        key: 'magic-kybd-light',
        label: 'Magic light keyboard',
        background: 'linear-gradient(303.69deg, #AF4261 6.67%, #2A2A72 93.33%)',
        schemeBarColors: ['white'],
        themeProps: {
          variant: 'light',
        }
      },
    ]

  },

  {
    id: '2',
    label: 'LOW PROFILE',
    key: 'low-profile',
    gradient: 'linear-gradient(135deg, #F0F0F0 11.92%, #F4A605 47.23%, #5A3E07 85%)',
    colorScheme: [
      {
        id: '1',
        key: 'low-profile-figma',
        label: 'Figma low profile keyboard',
        background: '#CECECE',
        schemeBarColors: ['#E95757', '#4BB7CA', '#53C198'],
        themeProps: {
          colors: {
            primary: {
              face: '#E95757',
              body: '#D94848',
            },
            secondary: {
              face: '#4BB7CA',
              body: '#3397A9',
            },
            default: {
              face: '#53C198',
              body: '#38A47C',
            }
          }
        },
      },
      {
        id: '2',
        key: 'low-profile-pink',
        label: 'Pink low profile keyboard',
        background: '#CECECE',
        schemeBarColors: ['#E577A2', '#78D8E5', '#F4F4F4'],
        themeProps: {
          colors: {
            primary: {
              face: '#E577A2',
              body: '#CF6A92',
            },
            secondary: {
              face: '#78D8E5',
              body: '#6BC4D0',
            },
            default: {
              face: '#F4F4F4',
              body: '#E5E3E3',
            }
          }
        },
      },
      {
        id: '3',
        key: 'low-profile-yellow',
        label: 'Yellow low profile keyboard',
        background: '#CECECE',
        schemeBarColors: ['#F4A605', '#A19E9A', '#F0F0F0'],
        themeProps: {
          colors: {
            primary: {
              face: '#F4A605',
              body: '#AF790B',
            },
            secondary: {
              face: '#A19E9A',
              body: '#908D88',
            },
            default: {
              face: '#F0F0F0',
              body: '#D5D4D3',
            }
          }
        },
      },
    ],
    isNew: true,
  },
  {
    id: '100',
    label: 'GLASS',
    key: 'glass',
    name: 'glass',
    gradient: 'linear-gradient(120deg, #887ECB 0%, #333 100%)',
    comingSoon: true,
  },
]
