import { RenderKeycap } from '../../components/RenderKeycap'
import { useOSKey } from '../../hooks/useOSKey'
import { useShortcutContext } from '../../hooks/useShortcutContext'
import style from './keycap.module.css'

import { getSize, getColor } from './utils'

export const Keycap = ({ index, keyPreview }) => {

  const { keycap } = useOSKey(keyPreview)
  const { colorScheme } = useShortcutContext()

  const color = getColor(keycap.origin, colorScheme.themeProps)
  const size = getSize(keycap.origin)

  return (
    <div key={index} className={`${style.keycapContainer} ${style[`keycapContainer${size}`]}`}>
      <div
        className={`${style.keycap} ${style[`keycap${size}`]}`}
        style={{
          backgroundColor: color.face,
          color: color.body,
        }}
      >
        <span className={style.keycapText}>
          <RenderKeycap keycap={keycap} isReactElement={keycap.isReactElement} svgColor={'black'} />
        </span>
      </div>
      <div className={`${style.switch} ${style[`switch${size}`]}`} />
    </div>
  )
}
