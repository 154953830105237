import { useOSKey } from '../../hooks/useOSKey'
import { RenderKeycap } from '../../components/RenderKeycap'
import style from './keycap.module.css'
import { useShortcutContext } from '../../hooks/useShortcutContext'

export const Keycap = (props) => {
  const { keyPreview } = props
  const { keycap } = useOSKey(keyPreview)
  const { colorScheme } = useShortcutContext()

  return (
    <span
      className={`${style.keyCap}
      ${style['size-' + (keyPreview.shortcutSize ? keyPreview.shortcutSize : 1)]}
      ${style['keyCap-' + colorScheme.themeProps.variant]}
    `}
    >
      <span className={style.keyCapText}>
        <RenderKeycap
          keycap={keycap}
        />
      </span>
    </span>
  )
}
