import style from './style.module.css'
import { SvgRemover } from '../Svg'
import { useShortcutContext } from '../../hooks/useShortcutContext'
import { onClickToggleTransparencyEvent } from '../../analitycs/events'

export const ToggleTransparent = () => {
  const { toggleCanvasTransparent, isCanvasTransparent } = useShortcutContext()

  const doToggleCanvasTransparent = () => {
    toggleCanvasTransparent()
    onClickToggleTransparencyEvent()
  }
  return (
    <button aria-label='Toggle transparency' className={style.toggle} onClick={doToggleCanvasTransparent}>
      <span className={`${style.icon} ${isCanvasTransparent ? style.active : ''}`}>
        <SvgRemover color='white' />
      </span>
    </button>
  )
}
