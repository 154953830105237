export const RenderKeycap = (props) => {

  const {
    keycap,
    svgColor,
  } = props
  if (!keycap) return null
  return keycap.isReactElement
    ? keycap.render({ size: keycap.svgSize, color: svgColor })
    : keycap.render
}
