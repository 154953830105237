import { UIConfig } from '../UIConfig'

import style from './style.module.css'

export const AboutUsFloatingButton = ({ openModal }) => {

  return (
    <UIConfig className={style.button} onChange={openModal}>
      <span className={style.text}>About</span>
    </UIConfig>
  )
}
