import { SvgFeedback } from '../Svg'
import { UIConfig } from '../UIConfig'

import style from './style.module.css'

export const Feedback = () => {

  const handleEmail = () => {
    window.location = `mailto:${import.meta.env.VITE_SUPPORT_EMAIL}?subject=Feedback&body=Hi, I have some feedback for you 🙂`;
  }

  return (
    <UIConfig onChange={handleEmail} className={style.feedback} active>
      <SvgFeedback />
    </UIConfig>
  )
}
