import {
  SvgCmd,
  SvgWindows,
  SvgLinux,
  SvgSpace,
  SvgControl,
  SvgOption,
} from '../components/Svg'

export const defaultShortcutMac = [
  {
    origin: 'meta',
    name: 'command',
    Aa: 'cmd',
    symbol: SvgCmd,
    size: 131,
    shortcutSize: 15,
    svgSize: 17,
    os: {
      mac: {
        name: 'command',
        Aa: 'cmd',
        symbol: SvgCmd,
        svgSize: 17,
      },
      windows: {
        name: 'windows',
        Aa: 'win',
        symbol: SvgWindows,
      },
      linux: {
        name: 'super',
        Aa: 'super',
        symbol: SvgLinux,
      },
      other: {
        name: 'windows',
        Aa: 'win',
        symbol: SvgWindows,
      },
    }
  },
  {
    origin: 'space',
    name: 'space',
    Aa: 'space',
    symbol: SvgSpace,
    size: 718,
    shortcutSize: 25,
    svgSize: 18,
  },
]
export const defaultShortcutWindows = [
  {
    origin: 'control',
    name: 'control',
    Aa: 'ctrl',
    symbol: SvgControl,
    size: 1,
    shortcutSize: 15,
    svgSize: 16,
  },
  {
    origin: 'alt',
    name: 'alt',
    Aa: 'alt',
    symbol: 'alt',
    size: 131,
    shortcutSize: 15,
    os: {
      mac: {
        name: 'option',
        Aa: 'opt',
        symbol: SvgOption,
        svgSize: 18,
      },
      windows: {
        name: 'alt',
        Aa: 'alt',
        symbol: 'alt',
      },
      linux: {
        name: 'alt',
        Aa: 'alt',
        symbol: 'alt',
      },
      other: {
        name: 'alt',
        Aa: 'alt',
        symbol: 'alt',
      }
    }
  },
  {
    origin: 'delete',
    name: 'supr',
    Aa: 'supr',
    symbol: 'supr',
    size: 1,
  },
]


export const defaultShortcutLinux = [
  {
    origin: 'alt',
    name: 'alt',
    Aa: 'alt',
    symbol: 'alt',
    size: 131,
    shortcutSize: 15,
    os: {
      mac: {
        name: 'option',
        Aa: 'opt',
        symbol: SvgOption,
        svgSize: 18,
      },
      windows: {
        name: 'alt',
        Aa: 'alt',
        symbol: 'alt',
      },
      linux: {
        name: 'alt',
        Aa: 'alt',
        symbol: 'alt',
      },
      other: {
        name: 'alt',
        Aa: 'alt',
        symbol: 'alt',
      }
    }
  },
  {
    origin: 'f2',
    name: 'f2',
    Aa: 'f2',
    symbol: 'f2',
    size: 1,
  },
]
