import { CursorPreview } from '../../components/CursosPreview'
import { useShortcutContext } from '../../hooks/useShortcutContext'
import { Keycap } from './Keycap'
import style from './style.module.css'

const LowProfile = ({ forDownload }) => {

  const {
    shortcut,
    isCanvasTransparent,
  } = useShortcutContext()

  return (
    <div className={`${style.container} ${forDownload ? style.containerDownload : ''}`}>
      <div className={`${style.panel} ${isCanvasTransparent && forDownload ? style.removeShadow : ''}`}>
        <div className={style.content}>
          {
            shortcut.map((key, index) => (
              <Keycap key={index} index={index} keyPreview={key} />
            ))
          }


          {
            !forDownload && <CursorPreview className={style.cursor} />
          }

        </div>
        <span className={style.shotki}>ShotKi</span>
      </div>
    </div>
  )
}


export default LowProfile
