import { useShortcutContext } from '../../hooks/useShortcutContext'
import style from './style.module.css'

export const ComingSoon = () => {
  const { comingSoon } = useShortcutContext()

  if (!comingSoon) return null
  return (
    <div className={style.comingSoon}>
      <img src={`/coming_soon/${comingSoon.key}.jpg`} alt="Low Profile" className={style.image} />
      <span className={style.ribbon}>
        COMING SOON
      </span>
    </div >
  )
}
