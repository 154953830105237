import { useEffect, useState } from 'react'
import { useDimensions } from './useDimensions'

export const useOS = () => {
  const [os, setOs] = useState(undefined)
  const [device, setDevice] = useState(undefined)
  const [isTouchScreen, setISTouchScreen] = useState(null)
  const { width } = useDimensions()

  useEffect(() => {
    const userAgent = window.navigator.userAgent

    if (userAgent.indexOf('iPad') !== -1) {
      setOs('mac')
      setDevice('tablet')
    } else if (userAgent.indexOf('iPhone') !== -1) {
      setOs('mac')
      setDevice('mobile')
    } else if (userAgent.indexOf('Mac') !== -1) {
      setOs('mac')
      setDevice('desktop')
    } else if (userAgent.indexOf('Android') !== -1) {
      setOs('windows')
      setDevice('mobile')
    } else if (userAgent.indexOf('Linux') !== -1) {
      setOs('linux')
      setDevice('desktop')
    } else if (userAgent.indexOf('Win') !== -1) {
      setOs('windows')
      setDevice('desktop')
    } else {
      setOs('other')
      setDevice('other')
    }
  }, [])

  useEffect(() => {
    if (width < 794) {
      setDevice('mobile')
    } else {
      setDevice('desktop')
    }
  }, [width])

  useEffect(() => {
    const touchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    setISTouchScreen(touchScreen)
  }, [])


  return {
    os,
    device,
    isTouchScreen,
  }
}
