import { useState } from 'react'
import { useKeyboard } from '../../hooks/useKeyboard'
import { useShortcutContext } from '../../hooks/useShortcutContext'

import { Key } from './Key'

import './style.css'

import { virtualKeyboard } from '../../data/virtualKeyboard'

export const VirtualKeyboard = () => {

  const {
    isFocus,
    os,
    secondaryActive,
    isTouchScreen,
    updateShortcut,
    updateSecondaryActive,
    undo,
  } = useShortcutContext()

  const [keyPressed, setKeyPressed] = useState(undefined)

  const onKeyDown = (key) => {
    if (key?.origin === 'undo') {
      setKeyPressed('undo')
      undo()
      return
    }
    if (key?.origin === '.?{-}' || key?.origin === 'ABC') {
      setKeyPressed('.?{-}')

      updateSecondaryActive(!secondaryActive)
      return
    }

    if (secondaryActive && key?.secondary?.origin) {
      updateShortcut(key.secondary)
      setKeyPressed(key.secondary.origin)
      return
    }

    if (!key?.origin) return

    const { secondary, ...rest } = key

    updateShortcut(rest)
    setKeyPressed(key.origin)
  }

  const onKeyUp = () => {
    setKeyPressed(undefined)
  }

  useKeyboard(onKeyDown, onKeyUp, isFocus, secondaryActive)

  if (!os.length || isTouchScreen === null) return <div className='keyboard-empty' />

  return (
    <div className='keyboard' data-ignore-oc>
      {virtualKeyboard.map((row, index) => (
        <div key={index} className='keyboard_row' data-ignore-oc>
          {row.map((key, i) => (
            <Key
              key={`${index}-${i}`}
              keyData={key}
              activeKey={keyPressed}
              isTouchScreen={isTouchScreen}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
