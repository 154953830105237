import { forwardRef } from 'react'
import { useShortcutContext } from '../../hooks/useShortcutContext'

import style from './style.module.css'
import { useLazyTheme } from '../../hooks/useLazyTheme'


export const DownloadShortcut = forwardRef((_, ref) => {

  const {
    isCanvasTransparent,
    colorScheme,
  } = useShortcutContext()

  const LazyTheme = useLazyTheme()

  return (
    <div
      ref={!isCanvasTransparent ? ref : null}
      className={`
          ${style.shortcutContainer}
          ${isCanvasTransparent ? style.containerTransparent : style.containerSolid}
        `}
      style={{
        background: isCanvasTransparent ? 'transparent' : colorScheme.background,
      }}
    >

      <div ref={isCanvasTransparent ? ref : null} className={`
          ${style.content}
          ${isCanvasTransparent ? style.contentTransparent : style.contentSolid}
        `}
      >

        <LazyTheme forDownload />

      </div>
    </div>

  )
})
