import { Header } from '../Header'
import { Footer } from '../Footer'

import style from './style.module.css'

export const Mobile = () => (
  <>
    <Header isMobile />
    <main className={style.mobile}>
      <div>
        <p className={style.text}>
          With Shot<span className={style.highlight}>Ki</span>, you can create <span className={style.highlight}>stunning shortcut screenshots</span> just by typing your shortcut, picking a theme, and then exporting it!
        </p>
        <p className={style.text}>It supports <span className={style.highlight}>macOS</span>, <span className={style.highlight}>Windows</span>, and <span className={style.highlight}>Linux</span> keyboard layouts.</p>

        <iframe
          width="100%"
          height="300"
          src="https://www.youtube.com/embed/FWEvri25AXI?si=2JAOvuRMFbt5P4lw"
          title="ShotKi - YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          className={style.youtubeVideoMobile}
        >
        </iframe>


        <div className={style.disclaimer}>
          <p className={style.text}>
            Currently, we're working to enable this app on mobile devices.
          </p>

          <p className={style.text}>
            Please use a <span className={style.highlight}>desktop</span> or <span className={style.highlight}>tablet</span> browser for now.
          </p>
        </div>

      </div>
      <Footer isMobile />
    </main>
  </>

)
