import { useContext } from 'react'
import { Context } from '../store'

import {
  themeSelectorEvent,
  operatingSystemEvent,
  shortcutTypeEvent,
  colorSchemeSelectorEvent,
} from '../analitycs/events'

export const useShortcutContext = () => {
  const { state: {
    isFocus,
    shortcut,
    defaultShortcut,
    secondaryActive,
    comingSoon,
    isTouchScreen,
    isCanvasTransparent,
    isTakingScreenshot,
    config: {
      shortcutType,
      theme,
      os,
      colorScheme,
    },
  }, dispatch } = useContext(Context)

  const updateShortcut = (key) => {
    if (shortcut.length >= 4) return
    dispatch({ type: 'UPDATE_SHORTCUT', payload: key })
  }

  const updateTheme = (theme) => {
    dispatch({ type: 'UPDATE_THEME', payload: theme })
    themeSelectorEvent(theme)
  }

  const updateOS = (os) => {
    dispatch({ type: 'UPDATE_OS', payload: os })
    operatingSystemEvent(os)
  }

  const updateShortcutType = (shortcutType) => {
    dispatch({ type: 'UPDATE_SHORTCUT_TYPE', payload: shortcutType })
    shortcutTypeEvent(shortcutType)
  }

  const updateFocus = (focus) => {
    dispatch({ type: 'UPDATE_FOCUS', payload: focus })
  }

  const updateDefaultShortcut = (defaultShortcut) => {
    dispatch({ type: 'DEFAULT_SHORTCUT', payload: defaultShortcut })
  }

  const updateColorScheme = (colorScheme) => {
    dispatch({ type: 'UPDATE_COLOR_SCHEME', payload: colorScheme })
    colorSchemeSelectorEvent(colorScheme)
  }


  return {
    isFocus,
    shortcut,
    defaultShortcut,
    secondaryActive,
    shortcutType,
    theme,
    os,
    colorScheme,
    comingSoon,
    isTouchScreen,
    isCanvasTransparent,
    isTakingScreenshot,
    updateShortcut,
    updateTheme,
    updateOS,
    updateShortcutType,
    updateFocus,
    updateDefaultShortcut,
    undo: () => dispatch({ type: 'UNDO' }),
    updateSecondaryActive: (active) => dispatch({ type: 'SECONDARY_ACTIVE', payload: active }),
    updateComingSoon: (option) => dispatch({ type: 'COMING_SOON', payload: option }),
    updateTouchScreen: (option) => dispatch({ type: 'TOUCH_SCREEN', payload: option }),
    toggleCanvasTransparent: () => dispatch({ type: 'TOGGLE_CANVAS_TRANSPARENT' }),
    toggleTakingScreenshot: () => dispatch({ type: 'TOGGLE_TAKING_SCREENSHOT' }),
    updateColorScheme: updateColorScheme,
  }
}
