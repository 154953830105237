import style from './style.module.css'
import { onClickVVLabsEvent, onClickAbout, onTwitterClick } from '../../analitycs/events'

export const Footer = ({ openModal, isMobile }) => {

  const doOpenModal = (event) => {
    event.preventDefault()
    openModal()
    onClickAbout()
  }

  const doOnTwitterClick = () => {
    onTwitterClick()
  }

  return (
    <footer className={style.footer}>
      <span>
        Made
      </span>
      <span>
        by
      </span>
      <a
        rel='stylesheet'
        href='https://vv-labs.com'
        target='_blank'
        className={style.link}
        onClick={onClickVVLabsEvent}
        title='Virtual Void Labs'
      >
        <span className={style.name}>Virtual</span>
        <span className={style.name}>Void</span>
        <span className={style.name}>Labs</span>
      </a>
      <span className={style.separador}>|</span>
      {
        !isMobile && (
          <>
            <a
              href='#'
              className={style.link}
              title='About'
              onClick={doOpenModal}
            >
              About
            </a>
            <span className={style.separador}>|</span>
          </>
        )
      }

      <a
        href='https://twitter.com/shotkiapp'
        target='_blank'
        className={style.link}
        title='Follow us on Twitter / X'
        onClick={doOnTwitterClick}
      >
        <svg width="14" height="14" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="#B7BBBC" />
        </svg>
      </a>

    </footer>
  )
}
