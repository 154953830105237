import { trackEvent } from './gtm';

export const onLoadEvent = (os) => {
  trackEvent('load', {
    category: 'page_load',
    label: os,
  });
};

export const themeSelectorEvent = (theme) => {
  trackEvent('themeSelectorEvent', {
    category: 'config_bar',
    label: 'theme_selected_' + theme.key,
  });
};

export const colorSchemeSelectorEvent = (colorScheme) => {
  trackEvent('colorSchemeSelectorEvent', {
    category: 'config_bar',
    label: 'color_scheme_selected_' + colorScheme.key,
  });
};

export const operatingSystemEvent = (os) => {
  trackEvent('operatingSystemEvent', {
    category: 'config_bar',
    label: 'os_selected_' + os,
  });
};

export const shortcutTypeEvent = (shortcutType) => {
  trackEvent('shortcutTypeEvent', {
    category: 'config_bar',
    label: 'shortcut_type_selected_' + shortcutType,
  });
};

export const onClickExportEvent = (x) => {
  trackEvent('onClickExportEvent', {
    category: 'config_bar',
    label: 'export_selected_' + x + 'x',
  });
};

export const shortcutDownloadEvent = (shortcut) => {
  trackEvent('shortcutDownloadEvent', {
    category: 'download',
    label: 'shortcut_generated_' + shortcut,
  });
};

export const onClickVVLabsEvent = () => {
  trackEvent('onClickVVLabsEvent', {
    category: 'link',
    label: 'Virtual Void Labs',
  });
};

export const onClickFeedbackEvent = () => {
  trackEvent('onClickFeedbackEvent', {
    category: 'link',
    label: 'Feedback',
  });
};

export const onClickToggleTransparencyEvent = () => {
  trackEvent('onClickToggleTransparencyEvent', {
    category: 'button',
  });
}

export const onClickAbout = () => {
  trackEvent('onClickAbout', {
    category: 'button',
    label: 'About',
  });
}

export const onTwitterClick = () => {
  trackEvent('onTwitterClick', {
    category: 'link',
  });
}
