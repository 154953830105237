import { useEffect, useState } from 'react'
import { useShortcutContext } from './useShortcutContext'

export const useRefDimensions = (ref) => {
  const [dimensions, setSimensions] = useState()
  const { shortcut, isCanvasTransparent, theme } = useShortcutContext()

  useEffect(() => {
    if (!ref.current) return
    setSimensions(ref.current.getBoundingClientRect())
  }, [ref, shortcut, isCanvasTransparent, theme])

  return dimensions
}
