import style from './style.module.css'

export const UIConfig = ({
  children,
  onChange,
  active,
  disabled,
  className = '',
}) => {
  const handleClick = () => {
    if (disabled) return
    onChange && onChange()
  }

  return (
    <button
      className={`${style.uiConfig} ${active ? style.uiConfigActive : ''} ${className} ${disabled ? style.disabled : ''}`}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}
