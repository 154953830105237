import { useRef } from 'react'
import { ShortcutPreview } from './components/ShortcutPreview'
import { VirtualKeyboard } from './components/VirtualKeyboard'
import { Configuration } from './components/Configuration'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Feedback } from './components/Feedback'
import { Mobile } from './components/Mobile'
import { Info } from './components/Info'
import { AboutModal } from './components/Modals/AboutModal'

import { useSetup } from './hooks/useSetup'
import { useDownload } from './hooks/useDownload'
import { useShortcutFocus } from './hooks/useShortcutFocus'
import { useModalState } from './hooks/useModalState'

import './normalize.css'
import style from './App.module.css'
import { DownloadShortcut } from './components/DownloasShortcut'
import { CanvasWidth } from './components/CanvasWidth'

function App() {

  const { device } = useSetup()
  const { ref } = useShortcutFocus()
  const downloadRef = useRef()
  const { handleDownload } = useDownload(downloadRef)
  const { isModalOpen, openModal, closeModal } = useModalState()

  if (!device) return null

  if (device === 'mobile') return (
    <Mobile />
  )

  return (
    <>
      <Header openModal={openModal} />
      <main className={style.main}>
        <DownloadShortcut ref={downloadRef} />
        <ShortcutPreview ref={ref} />
        <CanvasWidth ref={downloadRef} />
        <div className={style.info}>
          <Info />
        </div>
        <div className={style.virtualKeyboard}>
          <VirtualKeyboard />
        </div>
        <div className={style.configuration}>
          <Configuration handleDownload={handleDownload} />
        </div>

        <div className={style.feedback}>
          <Feedback />
        </div>
        <Footer openModal={openModal} />
        <AboutModal isOpen={isModalOpen} onClose={closeModal} />
      </main>
    </>

  )
}

export default App
