import { useState } from 'react'
import { useOnclickOutside } from './useOnclickOutside'

export const useFocus = (handleOutside) => {
  const [isFocus, setIsFocus] = useState(false)

  const onclickOutside = () => {
    setIsFocus(false)
    handleOutside && handleOutside()
  }
  const onclickInside = () => setIsFocus(true)

  const ref = useOnclickOutside(onclickOutside, onclickInside)

  const handleFocus = (state) => setIsFocus(state)

  return {
    ref,
    isFocus,
    handleFocus,
  }
}
