import { useEffect } from 'react'
import { virtualKeyboard } from '../data/virtualKeyboard'

const keyMap = {
  ' ': 'space',
  'backspace': 'del',
  'option': 'alt',
}

const virtualKeyboardFlat = virtualKeyboard.flat()

export const useKeyboard = (onKeyDown, onKeyUp, isFocus, secondaryActive) => {
  useEffect(() => {
    const handleKeyDown = (event) => {

      if (event.key === ' ') {
        event.preventDefault();
      }

      if (!isFocus) return
      const keyLowerCase = keyMap[event.key.toLowerCase()] ?? event.key.toLowerCase()
      if (secondaryActive && keyLowerCase === 'shift') return

      const key = virtualKeyboardFlat.find((key) => key.origin === keyLowerCase || key?.secondary?.origin === keyLowerCase)
      onKeyDown && onKeyDown(key)
    }

    const handleKeyUp = (event) => {
      if (event.key === ' ') {
        event.preventDefault();
      }

      if (!isFocus) return
      onKeyUp && onKeyUp()
    }

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    }
  }, [onKeyDown, isFocus])
}
