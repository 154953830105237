import { useShortcutContext } from './useShortcutContext'

export const useOSKey = (originKey) => {
  const {
    os,
    shortcutType,
    secondaryActive,
  } = useShortcutContext()

  const getKeyCap = () => {

    if (!originKey) return null

    return getKeyCapByKey(originKey)
  }

  const getKeyCapByKey = (key) => {
    if (!os || !key || !shortcutType) return
    const hasSecondary = !!key?.secondary
    const isOption = !!key?.option

    const { os: osKey, secondary, ...rest } = key
    const k = osKey
      ? osKey[os]
      : rest

    const render = k[shortcutType]

    return {
      isReactElement: typeof render === 'function',
      render,
      hasSecondary,
      isOption,
      ...rest,
      ...k,
      secondary,
    }
  }

  return {
    os,
    secondaryActive,
    keycap: getKeyCap(),
    getKeyCapByKey,
  }
}
