import { useEffect } from 'react'
import { toPng } from 'html-to-image'
import { onClickExportEvent, shortcutDownloadEvent } from '../analitycs/events'
import { db } from '../firebase'
import { useOSKey } from './useOSKey'
import { useSession } from './useSession'
import { useShortcutContext } from './useShortcutContext'
import { useRefDimensions } from './useRefDimensions'

import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
} from 'firebase/firestore'

export const useDownload = (ref) => {
  const { getKeyCapByKey } = useOSKey()
  const { sessionId } = useSession()
  const { toggleTakingScreenshot, isCanvasTransparent, colorScheme } = useShortcutContext()
  const dimensions = useRefDimensions(ref)

  const handleDownload = (x, shortcut, shortcutType, theme, os) => {
    handleIsTakingScreenshot()
    setTimeout(() => {
      download(x, shortcut, shortcutType, theme, os)
    }, 100)
  }

  const handleIsTakingScreenshot = () => {
    toggleTakingScreenshot()
    setTimeout(() => {
      toggleTakingScreenshot()
    }, 600)
  }

  const download = (x, shortcut, shortcutType, theme, os) => {
    onClickExportEvent(x)
    const shortcutName = shortcut.map((key) => getKeyCapByKey(key)?.Aa).join('-')
    const fileName = `shotki_${shortcutName}_${theme.key + (isCanvasTransparent ? '_transparent' : '')}_${x}x.png`
    const fileNameFirebase = fileName.replace('shotki_', '')

    saveToDb(x, fileNameFirebase, shortcutName, shortcutType, theme, os);
    toPng(ref.current, { cacheBust: true, pixelRatio: x })
      .then((dataUrl) => {
        shortcutDownloadEvent(shortcutName)
        const link = document.createElement('a');
        link.download = fileName;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }

  const saveToDb = async (x, fileName, shortcutName, shortcutType, theme, os) => {
    const shortcutData = {
      fileName,
      shortcut: shortcutName,
      shortcutType,
      theme: theme.key,
      colorScheme: colorScheme.key,
      layoutOs: os,
      extension: 'png',
      isTransparent: isCanvasTransparent,
      ratio: x,
      width: dimensions?.width,
      height: dimensions?.height,
      device: {
        userAgent: navigator.userAgent || 'unknown',
        hostOS: navigator.platform || 'unknown',
      },
    }

    const docRef = doc(db, import.meta.env.VITE_ENV === 'production' ? 'sessions' : 'sessions-test', sessionId);

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, {
          shortcuts: arrayUnion(shortcutData),
          updatedAt: serverTimestamp(),
        });
      } else {
        await setDoc(docRef, {
          shortcuts: [shortcutData],
          updatedAt: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error('Error accessing document: ', error);
    }
  }

  return { handleDownload }
}
