import style from './style.module.css'

export const Info = () => {
  return (
    <div className={style.info}>
      <p className={style.text}>
        Create stunning <span className={style.highlight}> shortcut </span> screenshots and share them with the hashtag <span className={style.highlight}>#shotki</span>
      </p>
    </div>
  )
}
