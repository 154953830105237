import { useEffect } from 'react'
import { onLoadEvent } from '../analitycs/events'
import { trackEvent } from '../analitycs/gtm'

import { useShortcutContext } from './useShortcutContext'
import { useOS } from './useOS'
import { useSession } from './useSession'
import {
  defaultShortcutMac,
  defaultShortcutWindows,
  defaultShortcutLinux,
} from '../data/defaultShorcut'

const defaultOSShortcut = {
  'mac': defaultShortcutMac,
  'windows': defaultShortcutWindows,
  'linux': defaultShortcutLinux,
}

export const useSetup = () => {
  const {
    updateOS,
    updateDefaultShortcut,
    updateTouchScreen,
  } = useShortcutContext()

  const { os, device, isTouchScreen } = useOS()
  const { sessionId } = useSession()

  const setDefaultShortcut = (os) => {
    updateDefaultShortcut(defaultOSShortcut[os] ?? defaultShortcutMac)
  }

  useEffect(() => {
    trackEvent('page_view', { page_path: window.location.pathname });
  }, []);

  useEffect(() => {
    if (!os) return
    updateOS(os)
    onLoadEvent(os)
    setDefaultShortcut(os)
  }, [os])

  useEffect(() => {
    updateTouchScreen(isTouchScreen)
  }, [isTouchScreen])

  useEffect(() => {
    sessionId
  }, [])

  return {
    device,
  }
}
