import { useOnclickOutside } from './useOnclickOutside'
import { useShortcutContext } from './useShortcutContext'

export const useShortcutFocus = (handleOutside) => {
  const {
    isFocus,
    updateFocus,
  } = useShortcutContext()

  const onclickOutside = (event) => {
    const ignoreOC = event.target.getAttribute('data-ignore-oc')
    if (ignoreOC) {
      updateFocus(true)
      return
    }
    updateFocus(false)
    handleOutside && handleOutside()
  }
  const onclickInside = () => updateFocus(true)

  const ref = useOnclickOutside(onclickOutside, onclickInside, true)

  const handleFocus = (state) => updateFocus(state)

  return {
    ref,
    isFocus,
    handleFocus,
  }
}
