import { CursorPreview } from '../../components/CursosPreview'
import { useShortcutContext } from '../../hooks/useShortcutContext'
import { Keycap } from './Keycap'

import style from './style.module.css'

const MagicKybdTheme = ({ forDownload }) => {
  const {
    shortcut,
    os,
    isCanvasTransparent,
    colorScheme: { themeProps: { variant } }
  } = useShortcutContext()


  return (
    <div className={style.container}>
      <div className={style.shortcutList}>
        {
          shortcut && shortcut.map((key, index) => (
            <Keycap
              key={index}
              keyPreview={key}
              uppercase={os !== 'mac'}
            />
          ))
        }
        {
          !forDownload && <CursorPreview />
        }
      </div>
      {
        !isCanvasTransparent && <span className={`${style.brand} ${style['brand-' + variant]}`}>ShotKi</span>
      }
    </div>

  )
}


export default MagicKybdTheme
