import { useShortcutContext } from './useShortcutContext'
import MagicKybd from '../themes/MagicKybd'
import LowProfile from '../themes/LowProfile'


const LazyComponent = {
  'magic-kybd': MagicKybd,
  'low-profile': LowProfile,
}

export const useLazyTheme = () => {
  const { theme } = useShortcutContext()

  return LazyComponent[theme.key]
}
