import { forwardRef } from 'react'
import { useShortcutContext } from '../../hooks/useShortcutContext'
import { useLazyTheme } from '../../hooks/useLazyTheme'

import style from './style.module.css'
import { ComingSoon } from '../ComingSoon'
import { ToggleTransparent } from '../ToggleTransparent'

const transparentPattern = {
  backgroundPosition: '0 0, 10px 10px',
  backgroundSize: '20px 20px',
  backgroundImage: 'repeating-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), repeating-linear-gradient(45deg, #ffffff 25%, #e5e5f7 25%, #e5e5f7 75%, #ffffff 75%, #ffffff)'
}

export const ShortcutPreview = forwardRef((_, ref) => {
  const {
    isCanvasTransparent,
    isTakingScreenshot,
    comingSoon,
    colorScheme,
  } = useShortcutContext()

  const LazyTheme = useLazyTheme()

  const backgroundStyle = isCanvasTransparent
    ? transparentPattern
    : {
      background: colorScheme.background
    }

  return (
    <section
      ref={ref}
      className={`${style.shortcutPreview} ${comingSoon && style.shortcutPreviewComingSoon}`}
      style={backgroundStyle}
    >

      <LazyTheme />

      <ComingSoon />
      {
        isTakingScreenshot &&
        <div className={style.takeScreenshot} />
      }
      <ToggleTransparent />
    </section>

  )
})
