import { useRef, useEffect } from 'react'

export const useOnclickOutside = (onclickOutside, onclickInside) => {
  const ref = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onclickOutside && onclickOutside(event)
        return
      }
      onclickInside && onclickInside()

    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [onclickOutside, onclickInside])



  return ref
}
