import style from './style.module.css'

export const ColorScheme = ({ scheme: { schemeBarColors }, className = '' }) => {
  return (
    <div className={`${style.colorScheme} ${className}`} data-ignore-oc>
      {
        schemeBarColors && schemeBarColors.map((color, index) => (
          <div
            data-ignore-oc
            key={index}
            className={style.color}
            style={{ backgroundColor: color }}
          />
        ))
      }
    </div>
  )
}
