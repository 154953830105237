import {
  SvgCmd,
  SvgWindows,
  SvgLinux,
  SvgUndo,
  SvgTab,
  SvgSpace,
  SvgCaps,
  SvgShift,
  SvgControl,
  SvgOption,
  SvgDel,
  SvgEnter,
  defaultSize,
} from '../components/Svg'

export const virtualKeyboard = [
  [
    {
      origin: 'esc',
      name: 'esc',
      Aa: 'esc',
      symbol: 'esc',
      size: 1,
    },
    {
      origin: 'f1',
      name: 'f1',
      Aa: 'f1',
      symbol: 'f1',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f2',
      name: 'f2',
      Aa: 'f2',
      symbol: 'f2',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f3',
      name: 'f3',
      Aa: 'f3',
      symbol: 'f3',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f4',
      name: 'f4',
      Aa: 'f4',
      symbol: 'f4',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f5',
      name: 'f5',
      Aa: 'f5',
      symbol: 'f5',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f6',
      name: 'f6',
      Aa: 'f6',
      symbol: 'f6',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f7',
      name: 'f7',
      Aa: 'f7',
      symbol: 'f7',
      size: 1,
    },
    {
      origin: 'f8',
      name: 'f8',
      Aa: 'f8',
      symbol: 'f8',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f9',
      name: 'f9',
      Aa: 'f9',
      symbol: 'f9',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f10',
      name: 'f10',
      Aa: 'f10',
      symbol: 'f10',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f11',
      name: 'f11',
      Aa: 'f11',
      symbol: 'f11',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'f12',
      name: 'f12',
      Aa: 'f12',
      symbol: 'f12',
      size: 1,
      uppercase: true,
    },
    {
      origin: 'undo',
      name: SvgUndo,
      Aa: SvgUndo,
      symbol: SvgUndo,
      size: 218,
      option: true,
      svgSize: 14,
    },
    {
      origin: 'delete',
      name: 'supr',
      Aa: 'supr',
      symbol: 'supr',
      size: 1,
    },
  ],
  [
    {
      origin: '`',
      name: '`',
      Aa: '`',
      symbol: '`',
      size: 1,
      secondary: {
        origin: '~',
        name: '~',
        Aa: '~',
        symbol: '~',
        size: 1,
      }
    },
    {
      origin: '1',
      name: '1',
      Aa: '1',
      symbol: '1',
      size: 1,
      secondary: {
        origin: '!',
        name: '!',
        Aa: '!',
        symbol: '!',
        size: 1,
      }
    },
    {
      origin: '2',
      name: '2',
      Aa: '2',
      symbol: '2',
      size: 1,
      secondary: {
        origin: '@',
        name: '@',
        Aa: '@',
        symbol: '@',
        size: 1,
      }
    },
    {
      origin: '3',
      name: '3',
      Aa: '3',
      symbol: '3',
      size: 1,
      secondary: {
        origin: '#',
        name: '#',
        Aa: '#',
        symbol: '#',
        size: 1,
      }
    },
    {
      origin: '4',
      name: '4',
      Aa: '4',
      symbol: '4',
      size: 1,
      secondary: {
        origin: '$',
        name: '$',
        Aa: '$',
        symbol: '$',
        size: 1,
      }
    },
    {
      origin: '5',
      name: '5',
      Aa: '5',
      symbol: '5',
      size: 1,
      secondary: {
        origin: '%',
        name: '%',
        Aa: '%',
        symbol: '%',
        size: 1,
      }
    },
    {
      origin: '6',
      name: '6',
      Aa: '6',
      symbol: '6',
      size: 1,
      secondary: {
        origin: '^',
        name: '^',
        Aa: '^',
        symbol: '^',
        size: 1,
      }
    },
    {
      origin: '7',
      name: '7',
      Aa: '7',
      symbol: '7',
      size: 1,
      secondary: {
        origin: '&',
        name: '&',
        Aa: '&',
        symbol: '&',
        size: 1,
      }
    },
    {
      origin: '8',
      name: '8',
      Aa: '8',
      symbol: '8',
      size: 1,
      secondary: {
        origin: '*',
        name: '*',
        Aa: '*',
        symbol: '*',
        size: 1,
      }
    },
    {
      origin: '9',
      name: '9',
      Aa: '9',
      symbol: '9',
      size: 1,
      secondary: {
        origin: '(',
        name: '(',
        Aa: '(',
        symbol: '(',
        size: 1,
      }
    },
    {
      origin: '0',
      name: '0',
      Aa: '0',
      symbol: '0',
      size: 1,
      secondary: {
        origin: ')',
        name: ')',
        Aa: ')',
        symbol: ')',
        size: 1,
      }
    },
    {
      origin: '-',
      name: '-',
      Aa: '-',
      symbol: '-',
      size: 1,
      secondary: {
        origin: '_',
        name: '_',
        Aa: '_',
        symbol: '_',
        size: 1,
      }
    },
    {
      origin: '=',
      name: '=',
      Aa: '=',
      symbol: '=',
      size: 1,
      secondary: {
        origin: '+',
        name: '+',
        Aa: '+',
        symbol: '+',
        size: 1,
      }
    },
    {
      origin: 'del',
      name: 'delete',
      Aa: 'del',
      symbol: SvgDel,
      size: 218,
      shortcutSize: 2,
      svgSize: 18,
    },
    {
      origin: 'pageup',
      name: 'pageup',
      Aa: 'PgUp',
      symbol: 'PgUp',
      size: 1,
    },
  ],
  [
    {
      origin: 'tab',
      name: 'tab',
      Aa: 'tab',
      symbol: SvgTab,
      size: 162,
      shortcutSize: 2,
      svgSize: 18,
    },
    {
      origin: 'q',
      name: 'q',
      Aa: 'q',
      symbol: 'q',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'w',
      name: 'w',
      Aa: 'w',
      symbol: 'w',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'e',
      name: 'e',
      Aa: 'e',
      symbol: 'e',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'r',
      name: 'r',
      Aa: 'r',
      symbol: 'r',
      size: 1,
      isLetter: true,
    },
    {
      origin: 't',
      name: 't',
      Aa: 't',
      symbol: 't',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'y',
      name: 'y',
      Aa: 'y',
      symbol: 'y',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'u',
      name: 'u',
      Aa: 'u',
      symbol: 'u',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'i',
      name: 'i',
      Aa: 'i',
      symbol: 'i',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'o',
      name: 'o',
      Aa: 'o',
      symbol: 'o',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'p',
      name: 'p',
      Aa: 'p',
      symbol: 'p',
      size: 1,
      isLetter: true,
    },
    {
      origin: '[',
      name: '[',
      Aa: '[',
      symbol: '[',
      size: 1,
      secondary: {
        origin: '{',
        name: '{',
        Aa: '{',
        symbol: '{',
        size: 1,
      }
    },
    {
      origin: ']',
      name: ']',
      Aa: ']',
      symbol: ']',
      size: 1,
      secondary: {
        origin: '}',
        name: '}',
        Aa: '}',
        symbol: '}',
        size: 1,
      }
    },
    {
      origin: `\\`,
      name: `\\`,
      Aa: `\\`,
      symbol: `\\`,
      size: 162,
      secondary: {
        origin: '|',
        name: '|',
        Aa: '|',
        symbol: '|',
        size: 162,
      }
    },
    {
      origin: 'pagedown',
      name: 'pagedown',
      Aa: 'PgDn',
      symbol: 'PgDn',
      size: 1,
    },
  ],
  [
    {
      origin: 'capslock',
      name: 'capslock',
      Aa: 'caps',
      symbol: SvgCaps,
      size: 187,
      shortcutSize: 2,
      svgSize: 18,
    },
    {
      origin: 'a',
      name: 'a',
      Aa: 'a',
      symbol: 'a',
      size: 1,
      isLetter: true,
    },
    {
      origin: 's',
      name: 's',
      Aa: 's',
      symbol: 's',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'd',
      name: 'd',
      Aa: 'd',
      symbol: 'd',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'f',
      name: 'f',
      Aa: 'f',
      symbol: 'f',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'g',
      name: 'g',
      Aa: 'g',
      symbol: 'g',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'h',
      name: 'h',
      Aa: 'h',
      symbol: 'h',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'j',
      name: 'j',
      Aa: 'j',
      symbol: 'j',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'k',
      name: 'k',
      Aa: 'k',
      symbol: 'k',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'l',
      name: 'l',
      Aa: 'l',
      symbol: 'l',
      isLetter: true,
      size: 1,
    },
    {
      origin: ';',
      name: ';',
      Aa: ';',
      symbol: ';',
      size: 1,
      secondary: {
        origin: ':',
        name: ':',
        Aa: ':',
        symbol: ':',
        size: 1,
      }
    },
    {
      origin: `'`,
      name: `'`,
      Aa: `'`,
      symbol: `'`,
      size: 1,
      secondary: {
        origin: '"',
        name: '"',
        Aa: '"',
        symbol: '"',
        size: 1,
      }
    },
    {
      origin: 'enter',
      name: 'enter',
      Aa: 'enter',
      symbol: SvgEnter,
      size: 25,
      shortcutSize: 2,
      svgSize: 18,
    },
    {
      origin: 'home',
      name: 'home',
      Aa: 'home',
      symbol: 'home',
      size: 1,
    },
  ],
  [
    {
      origin: 'shift',
      name: 'shift',
      Aa: 'shift',
      symbol: SvgShift,
      size: 25,
      shortcutSize: 2,
      svgSize: 17,
    },
    {
      origin: 'z',
      name: 'z',
      Aa: 'z',
      symbol: 'z',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'x',
      name: 'x',
      Aa: 'x',
      symbol: 'x',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'c',
      name: 'c',
      Aa: 'c',
      symbol: 'c',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'v',
      name: 'v',
      Aa: 'v',
      symbol: 'v',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'b',
      name: 'b',
      Aa: 'b',
      symbol: 'b',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'n',
      name: 'n',
      Aa: 'n',
      symbol: 'n',
      size: 1,
      isLetter: true,
    },
    {
      origin: 'm',
      name: 'm',
      Aa: 'm',
      symbol: 'm',
      size: 1,
      isLetter: true,
    },
    {
      origin: ',',
      name: ',',
      Aa: ',',
      symbol: ',',
      size: 1,
      secondary: {
        origin: '<',
        name: '<',
        Aa: '<',
        symbol: '<',
        size: 1,
      }
    },
    {
      origin: '.',
      name: '.',
      Aa: '.',
      symbol: '.',
      size: 1,
      secondary: {
        origin: '>',
        name: '>',
        Aa: '>',
        symbol: '>',
        size: 1,
      }
    },
    {
      origin: '/',
      name: '/',
      Aa: '/',
      symbol: '/',
      size: 1,
      secondary: {
        origin: '?',
        name: '?',
        Aa: '?',
        symbol: '?',
        size: 1,
      }
    },
    {
      origin: 'shift',
      name: 'shift',
      Aa: 'shift',
      symbol: SvgShift,
      size: 187,
      shortcutSize: 2,
      svgSize: 17,
    },
    {
      origin: 'arrowup',
      name: '↑',
      Aa: '↑',
      symbol: '↑',
      size: 1,
    },
    {
      origin: 'end',
      name: 'end',
      Aa: 'end',
      symbol: 'end',
      size: 1,
    },
  ],
  [
    {
      origin: 'control',
      name: 'control',
      Aa: 'ctrl',
      symbol: SvgControl,
      size: 131,
      shortcutSize: 15,
      os: {
        mac: {
          name: 'control',
          Aa: 'ctrl',
          symbol: SvgControl,
          svgSize: 16,
        },
        windows: {
          name: 'control',
          Aa: 'ctrl',
          symbol: 'ctrl',
        },
        linux: {
          name: 'control',
          Aa: 'ctrl',
          symbol: 'ctrl',
        },
        other: {
          name: 'control',
          Aa: 'ctrl',
          symbol: 'ctrl',
        }
      }
    },
    {
      origin: 'alt',
      name: 'alt',
      Aa: 'alt',
      symbol: 'alt',
      size: 131,
      shortcutSize: 15,
      os: {
        mac: {
          name: 'option',
          Aa: 'opt',
          symbol: SvgOption,
          svgSize: 18,
        },
        windows: {
          name: 'alt',
          Aa: 'alt',
          symbol: 'alt',
        },
        linux: {
          name: 'alt',
          Aa: 'alt',
          symbol: 'alt',
        },
        other: {
          name: 'alt',
          Aa: 'alt',
          symbol: 'alt',
        }
      }
    },
    {
      origin: 'meta',
      name: 'command',
      Aa: 'cmd',
      symbol: SvgCmd,
      size: 131,
      shortcutSize: 15,
      svgSize: defaultSize,
      os: {
        mac: {
          name: 'command',
          Aa: 'cmd',
          symbol: SvgCmd,
          svgSize: 17,
        },
        windows: {
          name: 'windows',
          Aa: 'win',
          symbol: SvgWindows,
        },
        linux: {
          name: 'super',
          Aa: 'super',
          symbol: SvgLinux,
        },
        other: {
          name: 'windows',
          Aa: 'win',
          symbol: SvgWindows,
        },
      }
    },
    {
      origin: 'space',
      name: 'space',
      Aa: 'space',
      symbol: SvgSpace,
      size: 718,
      shortcutSize: 25,
      svgSize: 18,
    },
    // {
    //   origin: 'meta',
    //   name: 'command',
    //   Aa: 'cmd',
    //   symbol: '⌘',
    //   size: 1,
    //   os: {
    //     mac: {
    //       name: 'command',
    //       Aa: 'cmd',
    //       symbol: <SvgCmd />,
    //     },
    //     windows: {
    //       name: 'windows',
    //       Aa: 'win',
    //       symbol: <SvgWindows />,
    //     },
    //     linux: {
    //       name: 'super',
    //       Aa: 'super',
    //       symbol: <SvgLinux />,
    //     },
    //     other: {
    //       name: 'windows',
    //       Aa: 'win',
    //       symbol: <SvgWindows />,
    //     },
    //   }
    // },
    {
      origin: '.?{-}',
      name: '.?{-}',
      Aa: '.?{-}',
      symbol: '.?{-}',
      size: 1,
      option: true,
      secondary: {
        origin: '',
        name: 'ABC',
        Aa: 'ABC',
        symbol: 'ABC',
        size: 1,
        option: true,
      }
    },
    {
      origin: 'fn',
      name: 'fn',
      Aa: 'fn',
      symbol: 'fn',
      size: 1,
    },
    {
      origin: 'control',
      name: 'control',
      Aa: 'ctrl',
      symbol: SvgControl,
      size: 1,
      shortcutSize: 15,
      os: {
        mac: {
          name: 'control',
          Aa: 'ctrl',
          symbol: SvgControl,
          svgSize: 16,
        },
        windows: {
          name: 'control',
          Aa: 'ctrl',
          symbol: 'ctrl',
        },
        linux: {
          name: 'control',
          Aa: 'ctrl',
          symbol: 'ctrl',
        },
        other: {
          name: 'control',
          Aa: 'ctrl',
          symbol: 'ctrl',
        }
      }
    },
    {
      origin: 'arrowleft',
      name: '←',
      Aa: '←',
      symbol: '←',
      size: 1,
    },
    {
      origin: 'arrowdown',
      name: '↓',
      Aa: '↓',
      symbol: '↓',
      size: 1,
    },
    {
      origin: 'arrowright',
      name: '→',
      Aa: '→',
      symbol: '→',
      size: 1,
    },
  ]
]
