import { AboutUsFloatingButton } from '../AboutUsFloatingButton'
import { onClickAbout } from '../../analitycs/events'
import style from './style.module.css'

export const Header = ({ isMobile, openModal }) => {

  const doOpenModal = (event) => {
    openModal()
    onClickAbout()
  }

  return (
    <header className={style.header}>
      <a href="/" title='ShotKi' className={style.container}>
        <img src='/logo-keycap.svg' alt='ShotKi' title='ShotKi' className={style.logo} />
        <h1 className={style.title}>
          Shot
          <span className={style.highlight}>Ki</span>
        </h1>
      </a>
      {
        !isMobile && <AboutUsFloatingButton openModal={doOpenModal} />
      }
    </header>
  )
}
