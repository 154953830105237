import { v4 as uuidv4 } from 'uuid'

export const useSession = () => {

  const getSessionId = () => {
    let sessionId
    try {
      sessionId = sessionStorage.getItem('sessionId')
      if (!sessionId) {
        sessionId = uuidv4()
        sessionStorage.setItem('sessionId', sessionId)
      }
    } catch (error) {
      console.error('Error accessing session storage:', error)
      sessionId = null
    }
    return sessionId
  }

  const sessionId = getSessionId()

  return { sessionId }

}
