import { useShortcutContext } from '../../hooks/useShortcutContext'
import { Select } from '../Select'
import { UIConfig } from '../UIConfig'
import { LabelConfig } from '../LabelConfig'
import { SvgApple, SvgWindows, SvgLinux, SvgShortcutType } from '../Svg'

import { themes } from '../../themes/config'

import style from './style.module.css'


export const Configuration = ({ handleDownload }) => {

  const {
    shortcut,
    os,
    theme,
    shortcutType,
    updateTheme,
    updateOS,
    updateShortcutType,
    updateComingSoon,
    updateColorScheme,
    comingSoon,
    colorScheme,
  } = useShortcutContext()

  const disabledDownload = !shortcut.length || comingSoon

  return (
    <section className={style.configuration}>
      <LabelConfig label="Keyboard Style">
        <Select
          options={themes}
          defaultValue={theme}
          onChange={updateTheme}
          onComingSoon={updateComingSoon}
        />
      </LabelConfig>

      <LabelConfig label="Color Scheme">
        <Select
          useComponent
          className={style.colorScheme}
          options={theme.colorScheme}
          defaultValue={colorScheme}
          onChange={updateColorScheme}
        // onComingSoon={updateComingSoon}
        />
      </LabelConfig>

      <LabelConfig label="Operating System">
        <UIConfig active={os === 'mac'} onChange={() => updateOS('mac')}>
          <SvgApple />
        </UIConfig>
        <UIConfig active={os === 'windows'} onChange={() => updateOS('windows')}>
          <SvgWindows />
        </UIConfig>
        <UIConfig active={os === 'linux'} onChange={() => updateOS('linux')}>
          <SvgLinux />
        </UIConfig>
      </LabelConfig>

      <LabelConfig label="Shortcut type">
        <UIConfig active={shortcutType === 'Aa'} onChange={() => updateShortcutType('Aa')}>
          Aa
        </UIConfig>
        <UIConfig active={shortcutType === 'symbol'} onChange={() => updateShortcutType('symbol')}>
          <SvgShortcutType />
        </UIConfig>
      </LabelConfig>

      <div className={style.export}>
        <LabelConfig label="Export (PNG)" exportStyle>
          <UIConfig
            className={style.exportStyle}
            disabled={disabledDownload}
            onChange={() => handleDownload(1, shortcut, shortcutType, theme, os)}
          >
            1x
          </UIConfig>
          <UIConfig
            className={style.exportStyle}
            disabled={disabledDownload}
            onChange={() => handleDownload(2, shortcut, shortcutType, theme, os)}
          >
            2x
          </UIConfig>
        </LabelConfig>
      </div>
    </section>
  )
}
