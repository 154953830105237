import { useEffect } from 'react'
import style from './style.module.css';

export const AboutModal = ({ isOpen, onClose }) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  return (
    <>
      <div className={`${style.backdrop} ${isOpen ? style.backdropOpen : style.backdropClose}`} onClick={onClose}></div>
      <div className={`${style.modal} ${isOpen ? style.modalOpen : style.modalClose}`}>
        <div className={style.modalContent}>
          <h2 className={style.title}>About</h2>
          <div className={style.text}>
            <p className={style.paragraph}>
              With Shot<span className={style.highlight}>Ki</span>, you can create stunning shortcut screenshots just by simply typing your shortcut (or using our on-screen keyboard), picking your favorite theme, and then exporting it! It supports macOS, Windows, and Linux keyboard layouts.
            </p>
            <p className={style.paragraph}>
              It supports macOS, Windows, and Linux keyboard layouts.
            </p>

            <p className={style.paragraph}>
              This is a free project, so by sharing your creations with the hashtag <span className={style.highlight}>#shotki</span> on social media, you are helping us spread the word and reach more people. Your support and enthusiasm are crucial to the growth and success of this project. Thank you for being a part of our community!
            </p>
            <p className={style.paragraph}>
              Made by <a rel='stylesheet' href='https://vv-labs.com' target='_blank' className={style.link} title='Virtual Void Labs'>Virtual Void Labs</a>
            </p>
            <p className={style.paragraph}>
              Follow us on Twitter/X <a href='https://twitter.com/shotkiapp' target='_blank' className={style.link} title='Follow us on Twitter'>@shotkiapp</a>

            </p>
          </div>
          <button onClick={onClose} className={style.closeButton}>X</button>
        </div>
      </div>
    </>
  )
}
