import { useEffect, useState } from 'react'

import { SvgArrowDown } from '../Svg'

import { useFocus } from '../../hooks/useFocus'

import style from './style.module.css'
import { ColorScheme } from '../ColorScheme'

export const Select = ({ options, defaultValue, onChange, onComingSoon, useComponent, className = '' }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(defaultValue)

  const { ref } = useFocus(() => {
    setIsOpen(false)
  })

  const handleSelect = (option) => {
    setIsOpen(false)
    setSelected(option)
    if (option.comingSoon) {
      onComingSoon(option)
      return
    }
    onChange && onChange(option)
    onComingSoon && onComingSoon(null)
  }

  useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])

  return (
    <div
      ref={ref}
      className={`${style.select} ${className}`}
    >
      <button
        data-ignore-oc
        aria-label={selected.label}
        className={style.optionSelected}
        onClick={() => setIsOpen(!isOpen)}>
        {
          useComponent
            ? <ColorScheme scheme={selected} className={style.useComponent} />
            : selected.label
        }

        <span className={`${style.icon} ${isOpen ? style.arrowUp : style.arrowDown}`} data-ignore-oc>
          <SvgArrowDown color={'#00D992'} data-ignore-oc />
        </span>
      </button>
      <div
        className={`
          ${style.options}
          ${isOpen ? style.openSelect : style.openSelectHidden}
        `}
      >
        {options.map((option) => (
          <button
            data-ignore-oc
            aria-label={option.label}
            key={option.id}
            className={`
              ${style.option}
              ${selected.id === option.id ? style.selected : ''}
            `}
            onClick={() => handleSelect(option)}
          >
            {

              !useComponent && <div className={style.gradient} style={{ background: option.gradient }} />
            }
            {
              option.comingSoon && (
                <span className={`${style.optionLabel} ${style.comingSoonLabel}`}>Soon</span>
              )
            }
             {
              option.isNew && (
                <span className={`${style.optionLabel} ${style.newLabel}`}>New</span>
              )
            }
            {
              useComponent
                ? <ColorScheme scheme={option} />
                : option.label
            }
            {/* {option.label} */}
          </button>
        ))}
      </div>
    </div>
  )
}
