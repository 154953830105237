import { useShortcutContext } from '../../hooks/useShortcutContext'
import style from './style.module.css'

export const CursorPreview = ({ className = '' }) => {
  const {
    isFocus,
    shortcut,
  } = useShortcutContext()

  if (!isFocus) return null
  return (
    <span className={`${style.cursor} ${shortcut.length === 0 ? style.cursorCenter : ''} ${className}`} />
  )
}
