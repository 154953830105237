import { useRef, useEffect } from 'react'
import './style.css'
import { useOSKey } from '../../hooks/useOSKey'
import { RenderKeycap } from '../RenderKeycap'

export const Key = ({
  keyData,
  activeKey,
  onKeyDown,
  onKeyUp,
  isTouchScreen,
}) => {

  const ref = useRef(null)

  const {
    keycap,
    secondaryActive,
    os,
  } = useOSKey(keyData)

  const keyPressed = keycap.origin === activeKey

  useEffect(() => {
    if (!keyPressed) return

    if (
      keycap.origin === 'capslock' ||
      keycap.origin === 'tab'
    ) {
      setTimeout(() => {
        onKeyUp()
      }, 100)
    }

  }, [keyPressed])

  const uppercase = (keycap.isLetter && os !== 'mac') || keycap.uppercase

  const handleClick = () => {
    if (keycap.disabled) return
    onKeyDown(keyData)
  }


  const renderKeycapOptions = () => {
    if (!keycap.option) return null
    return (
      <RenderKeycap
        keycap={keycap}
      />
    )
  }

  const renderKeycap = () => {
    if (keycap.option) return null
    return (
      <span className={`
      keyboard_row_key_text-base
      ${secondaryActive && keycap.hasSecondary ? 'keyboard_row_key_text--opacity' : ''}
    `}
        data-ignore-oc
      >
        <RenderKeycap
          keycap={keycap}
        />
      </span>
    )
  }

  const renderSecondaryKeycap = () => {
    if (keycap.option || !keycap.hasSecondary) return null

    return (
      <span className={`
      keyboard_row_key_text-base
      ${!secondaryActive && keycap.hasSecondary ? 'keyboard_row_key_text--opacity' : ''}
    `}
        data-ignore-oc
      >
        {keycap?.secondary?.Aa}
      </span>
    )
  }

  const renderUndoKeycap = () => {
    return keycap.origin === 'undo' ? 'undo' : ''
  }


  return (
    <div
      data-ignore-oc
      ref={ref}
      className={`
        keyboard_row_key
        key-width-${keycap.size}
        ${keyPressed ? 'pressed' : ''}
        ${keycap.option ? 'option' : ''}
        ${keycap.disabled ? 'disabled' : ''}
      `}
      onMouseDown={isTouchScreen ? null : handleClick}
      onMouseUp={onKeyUp}
      onPointerUp={onKeyUp}
      onContextMenu={onKeyUp}
      onTouchStart={isTouchScreen ? handleClick : null}
    >
      <span
        data-ignore-oc
        className={`
          keyboard_row_key_text
          ${keycap.option ? 'option' : ''}
          ${uppercase ? 'uppercase' : ''}
        `}
      >
        {renderKeycapOptions()}
        {renderUndoKeycap()}
        {renderKeycap()}
        {renderSecondaryKeycap()}
      </span>
    </div>
  )
}
